import React from 'react'
import AboutStyles from '../components/about-page-styles.module.less'

import Layout from '../components/layout'
import StickersOnTop from '../../static/assets/sticker-overlay.svg'
import MobileStickersOnTop from '../../static/assets/about-mobile-sticker-overlay.svg'

export default class AboutPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {}

	render() {
		// console.log(this.state.orangeBlobData)
		return (
			<Layout>
				<div className={AboutStyles.wrapper}>
					<div className={AboutStyles.grid}>
						<h1 className={AboutStyles.title}>
							The YSDN graduating class of 2019 focuses on the
							real life connections that are made between
							designers and the public.
						</h1>
						<iframe
							className={AboutStyles.map}
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.992222264231!2d-79.41301198407983!3d43.648330160762875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34cff7f272f5%3A0x20170ccb6ff85515!2sFree!5e0!3m2!1sen!2sca!4v1551620384406"
							width="792"
							height="483"
							frameBorder="0"
							// style="border:0"
							allowFullScreen
						/>
						<section className={AboutStyles.mapParagraphBlock}>
							<h2 className={AboutStyles.mapHeaderTwo}>
								Come join us at Free Studio in Toronto, Ontario!
							</h2>
							<p className={AboutStyles.mapHeaderParagraph}>
								Come to the show. See the work. Meet the
								graduates. Make connections. Get inspired. Share
								your passions, In Real Life.
							</p>
							<div>
								<h3 className={AboutStyles.mapHeader}>
									Industry Night
								</h3>
								<p className={AboutStyles.mapParagraph}>
									April 10th 6PM–8PM
								</p>
							</div>
							<div>
								<h3 className={AboutStyles.mapHeader}>
									Public Viewing
								</h3>
								<p className={AboutStyles.mapParagraph}>
									April 10th 8PM–11PM
								</p>
								<p className={AboutStyles.mapParagraph}>
									April 11th 11AM–4PM
								</p>
							</div>
						</section>
						<img
							className={AboutStyles.stickersOnTop}
							src={StickersOnTop}
							alt="sticker on top"
						/>
						<img
							className={AboutStyles.mobileStickersOnTop}
							src={MobileStickersOnTop}
							alt="sticker on top"
						/>
					</div>
				</div>
			</Layout>
		)
	}
}
